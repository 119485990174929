import { VStack } from "therese";
import { Template, TemplateSkeleton } from "./Template/Template";
import { useTemplates } from "./hooks/useTemplates";
import styles from "./styles.module.css";
import { AnyTemplate } from "./hooks/types";

interface TemplatesProps {
  onSelect: (template: AnyTemplate) => void;
  selectedTemplate?: AnyTemplate;
}

export const Templates = ({ onSelect, selectedTemplate }: TemplatesProps) => {
  const { userTemplates, cmsTemplates, isLoading } = useTemplates();

  const renderSkeletonTemplates = () =>
    Array(6)
      .fill(null)
      .map((_, i) => <TemplateSkeleton key={`skeleton:${i}`} />);

  const renderTemplates = () =>
    [...userTemplates, ...cmsTemplates].map((template: AnyTemplate) => (
      <Template
        onClick={() => onSelect(template)}
        key={template.title}
        template={template}
        selected={
          template.uid
            ? template.uid === selectedTemplate?.uid
            : template.id === selectedTemplate?.id
        }
      />
    ));

  return (
    <div className={styles.templates}>
      <VStack spacing="s">
        {isLoading ? renderSkeletonTemplates() : renderTemplates()}
      </VStack>
    </div>
  );
};
