import { useState } from "react";
import { VStack, UIText, Loading, Tab, TabItem, Button, Link } from "therese";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { Templates } from "./Templates";
import { NavLink } from "../../../../components/NavLink";
import { ReactComponent as Plus } from "symbols/plus.svg";
import { AnyTemplate } from "./hooks/types";

interface TemplatesViewProps {
  isLoading: boolean;
  categories: ITemplateCategory[];
  onDismiss: () => void;
  onUse: (content: string) => void;
}

export const TemplatesView = ({
  isLoading,
  categories,
  onDismiss,
  onUse,
}: TemplatesViewProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<
    AnyTemplate | undefined
  >(undefined);
  const navigate = useNavigate();

  const renderCategories = () =>
    categories.map((category) => (
      <TabItem
        key={category.displaySlug}
        as={NavLink}
        activeClassName={TabItem.activeClassName}
        to={`../maler/${category.displaySlug}`}
      >
        {category.title}
      </TabItem>
    ));

  return (
    <>
      <VStack spacing="s">
        <UIText as="h1" size="28" className={styles.title}>
          Tekstmaler
        </UIText>
        {isLoading ? (
          <Loading />
        ) : (
          <Tab className={styles.tabs}>
            {renderCategories()}
            <Link
              hideChildren
              icon={Plus}
              onClick={() => navigate("opprett")}
              theme="outline"
              className={styles.addButton}
              aria-label="Opprett egen tekstmal"
            />
          </Tab>
        )}
        <div className={styles.templatesContainer}>
          <Templates
            selectedTemplate={selectedTemplate}
            onSelect={(template) => setSelectedTemplate(template)}
          />
        </div>
      </VStack>
      <div className={styles.buttons}>
        <Button theme="outline" onClick={onDismiss}>
          Avbryt
        </Button>
        <Button
          theme="action"
          disabled={!selectedTemplate?.content}
          onClick={() => {
            onUse(selectedTemplate?.content || "");
            onDismiss();
          }}
        >
          Velg
        </Button>
      </div>
    </>
  );
};
