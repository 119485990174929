/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { MouseEvent } from "react";
import cx from "classnames";

import { UIText, Dropdown, Card } from "therese";
import { ReactComponent as Message } from "symbols/20/message.svg";
import { ReactComponent as More } from "symbols/20/more.svg";

import styles from "./styles.module.css";
import { useTemplates } from "../hooks/useTemplates";
import { useNavigate } from "react-router-dom";
import { AnyTemplate } from "../hooks/types";

interface ITemplateProps {
  template: AnyTemplate;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  selected?: boolean;
}

const Template: React.FC<ITemplateProps> = ({
  template,
  onClick,
  className,
  selected,
}) => {
  const { addFavorite, removeFavorite, deleteTemplate } = useTemplates();
  const navigate = useNavigate();

  return (
    <div
      role="button"
      tabIndex={-1}
      className={cx(styles.root, className, { [styles.selected]: selected })}
      onClick={onClick}
    >
      <div className={styles.thumbnail} aria-hidden={true}>
        <Message />
      </div>
      <div className={styles.body}>
        <UIText as="h2" size="14" bold className={styles.title}>
          {template.title}
        </UIText>
        <UIText className={styles.content} size="14" color="muted">
          {template.content}
        </UIText>
      </div>
      <Dropdown className={styles.dropdown} hideTitle icon={<More />}>
        <Card as="form">
          {template.can_add_favorite && (
            <button
              onClick={() => addFavorite(template)}
              className={styles.dropdownItem}
            >
              Lagre som favoritt
            </button>
          )}
          {template.can_remove_favorite && (
            <button
              onClick={() => removeFavorite(template)}
              className={styles.dropdownItem}
            >
              Fjern som favoritt
            </button>
          )}
          {template.can_edit && (
            <button
              onClick={() => navigate(`rediger/${template.id}`)}
              className={styles.dropdownItem}
            >
              Rediger mal
            </button>
          )}
          {template.can_edit && (
            <button
              onClick={() => deleteTemplate(template.id as number)}
              className={styles.dropdownItem}
            >
              Slett
            </button>
          )}
        </Card>
      </Dropdown>
    </div>
  );
};

interface ITemplateSkeletonProps {
  className?: string;
  compact?: boolean;
}

function TemplateSkeleton(props: ITemplateSkeletonProps) {
  return (
    <div className={cx(styles.skeleton, styles.root, props.className)}>
      <div className={styles.body}>
        <div className={styles.title} />
        <div />
      </div>
      <div className={styles.thumbnail} />
    </div>
  );
}

export { Template, TemplateSkeleton };
