import {
  useRef,
  useState,
  useEffect,
  useCallback,
  Suspense,
  useLayoutEffect,
} from "react";
import { InputText, Button, useMediaQuery, Link } from "therese";
import cx from "classnames";

import { useExpandWithContent, useVisualViewportChange } from "./hooks";
import { ReactComponent as ArrowUp } from "symbols/arrow_up.svg";
import { ReactComponent as Plus } from "symbols/plus.svg";

import styles from "./styles.module.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useProfile } from "../../../../components/useProfile";
import FavoriteTemplates from "../TemplateModal/FavoriteTemplates";
import { useTemplates } from "../TemplateModal/hooks/useTemplates";
import { favorites } from "../TemplateModal/hooks/types";
import { TemplateModal } from "../TemplateModal/TemplateModal";

interface IFormProps {
  onChange: () => void;
  onSubmit: (content: string) => void;
  disabled: boolean;
}

const Form: React.FC<IFormProps> = ({ onChange, onSubmit, disabled }) => {
  const form = useRef<HTMLFormElement>(null);
  const textarea = useRef<HTMLTextAreaElement>(null);
  const smallScreen = useMediaQuery(`(max-width: ${styles.medium})`);
  const [writing, setWriting] = useState(false);
  const navigate = useNavigate();
  const { data: user } = useProfile();

  const { isLoading, categories } = useTemplates();

  const templatesUrl = `maler/${
    categories[0]?.displaySlug || favorites.displaySlug
  }`;

  const { readjust } = useExpandWithContent(textarea);
  useVisualViewportChange();

  const handleSubmit = useCallback(() => {
    if (form.current) {
      const message = new FormData(form.current).get("message");
      if (message !== "" && typeof message === "string") {
        onSubmit(message);
        setWriting(false);
        form.current.reset();
      }
    }
  }, [onSubmit]);

  const onUseMessageTemplate = useCallback(
    (content: string) => {
      if (textarea.current) textarea.current.value = content;
      readjust();
    },
    [textarea]
  );

  useEffect(() => {
    const formElm = form.current;
    function handleKeyDown(event: KeyboardEvent): void {
      if (event.key !== "Enter" || !event.metaKey) {
        return;
      }
      handleSubmit();
    }

    if (formElm) {
      formElm.addEventListener("keydown", handleKeyDown);
      return (): void => {
        formElm.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [handleSubmit]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (writing) {
      onChange();
      interval = setInterval(() => {
        onChange();
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [writing, onChange]);

  useLayoutEffect(() => {
    const checkFormPosition: () => void = () => {
      const rect = form.current?.getBoundingClientRect();
      if (rect) {
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;

        const isInViewport = rect.bottom - 16 <= windowHeight;
        if (form.current) {
          form.current.style.paddingBottom = isInViewport
            ? "0"
            : rect.bottom - windowHeight + 16 + "px";
        }
      }
    };

    checkFormPosition();

    window.addEventListener("resize", checkFormPosition);
    return () => {
      window.removeEventListener("resize", checkFormPosition);
    };
  }, [disabled]);

  return !disabled ? (
    <>
      {user?.type === "agent" ? (
        <FavoriteTemplates onUseTemplate={onUseMessageTemplate} />
      ) : null}
      <div className={styles.formContainer}>
        <form
          className={styles.form}
          ref={form}
          onReset={() => {
            if (textarea.current) {
              textarea.current.style.height = "inherit";
            }
          }}
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <label className="visuallyhidden" htmlFor="message">
            Melding
          </label>
          <InputText
            className={cx(styles.input, {
              [styles.paddingRight]: user?.type === "agent", // space for message template button
            })}
            ref={textarea}
            type="textarea"
            id="message"
            name="message"
            placeholder="Skriv her..."
            rows="1"
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setWriting(event.target.value !== "");
            }}
          />
          {user?.type === "agent" ? (
            <div className={styles.messageTemplatesButtonContainer}>
              {!isLoading && (
                <button
                  className={styles.messageTemplatesButton}
                  aria-label="Maler"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(templatesUrl);
                  }}
                >
                  <Plus />
                </button>
              )}
            </div>
          ) : null}
          {smallScreen ? (
            <Link
              className={styles.smallButton}
              as="button"
              type="submit"
              icon={ArrowUp}
              hideChildren
            >
              Send
            </Link>
          ) : (
            <Button
              className={styles.button}
              theme="action"
              type="submit"
              size="large"
            >
              Send
              <ArrowUp aria-hidden />
            </Button>
          )}
          {user?.type === "agent" ? (
            <Suspense fallback={null}>
              <Routes>
                <Route
                  path="maler/:categoryId/*"
                  element={
                    <TemplateModal
                      isOpen
                      onUse={onUseMessageTemplate}
                      onDismiss={() => navigate("./")}
                      title="Tekstmaler"
                    />
                  }
                />
                <Route
                  path="maler/:categoryId/rediger/:templateId"
                  element={
                    <TemplateModal
                      isOpen
                      onDismiss={() => navigate("./")}
                      editing
                      onUse={onUseMessageTemplate}
                      title="Opprett egen tekstmal"
                    />
                  }
                />
                <Route
                  path="maler/:categoryId/opprett"
                  element={
                    <TemplateModal
                      isOpen
                      onDismiss={() => navigate("./")}
                      editing
                      onUse={onUseMessageTemplate}
                      title="Opprett egen tekstmal"
                    />
                  }
                />
              </Routes>
            </Suspense>
          ) : null}
        </form>
      </div>
    </>
  ) : null;
};

export default Form;
