import cx from "classnames";

import { UIText, Link } from "therese";
import { ReactComponent as ChevronLeft } from "symbols/chevron_left.svg";
import { ReactComponent as ChevronRight } from "symbols/chevron_right.svg";

import styles from "./styles.module.css";
import { IPagination } from "../useAgentProperties";

interface IPaginationProps {
  className?: string;
  pagination: IPagination;
  onChange: (offset: number) => void;
}

function Pagination(props: IPaginationProps) {
  const pagination = props.pagination;
  const totalPages = Math.floor(pagination.total / pagination.limit) + 1;
  const currentPage = Math.floor(
    (pagination.offset + Math.max(pagination.amount, pagination.limit)) /
      pagination.limit
  );
  const hasPreviousPage = pagination.offset > 0;
  const hasNextPage = pagination.offset + pagination.limit <= pagination.total;

  return (
    <div
      className={cx(styles.root, props.className)}
      role="navigation"
      aria-label="pagination"
    >
      <Link
        disabled={!hasPreviousPage}
        icon={ChevronLeft}
        onClick={() => props.onChange(pagination.offset - pagination.limit)}
        reverse
        hideChildren
      >
        Forrige side
      </Link>

      <UIText className={styles.info} size="14" as="span">
        Side {currentPage} av {totalPages}
      </UIText>

      <Link
        disabled={!hasNextPage}
        icon={ChevronRight}
        onClick={() => props.onChange(pagination.offset + pagination.limit)}
        hideChildren
      >
        Neste side
      </Link>
    </div>
  );
}

export { Pagination };
