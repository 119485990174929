import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  QueryObserverResult,
} from "react-query";
import redaxios from "redaxios";

type ErrorResponse<T extends PortalContext> = T extends "user"
  ? IGenericErrorResponse
  : IAgentProfileErrorResponse;

function useProfile<T extends PortalContext = typeof CONTEXT>(
  type?: T,
  options?: UseQueryOptions<IUser, ErrorResponse<T>>
): QueryObserverResult<IUser, ErrorResponse<T>> {
  return useQuery<IUser, ErrorResponse<T>>(
    profileEndpoint(type || CONTEXT),
    options
  );
}

function useProfileMutation(type: PortalContext = CONTEXT) {
  const client = useQueryClient();
  const queryKey = profileEndpoint(type);

  return useMutation(() => redaxios.delete(`/api/${queryKey}`), {
    onSuccess: () => client.setQueryData(queryKey, undefined),
  });
}

const profileEndpoint = (type: PortalContext) => {
  return `${type === "agent" ? "agents" : "v2"}/profile`;
};

export { useProfile, useProfileMutation, profileEndpoint };
