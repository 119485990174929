import React, { useCallback, useRef } from "react";
import {
  VStack,
  UIText,
  Loading,
  Button,
  Link,
  Form,
  Label,
  FormikInputText,
} from "therese";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useTemplates } from "./hooks/useTemplates";
import { ReactComponent as ArrowLeft } from "symbols/arrow_left.svg";
import { object, string } from "yup";
import { FormikProps } from "formik";

export const TemplateEditView: React.FC = () => {
  const { templateToEdit, editTemplate, isLoading, addTemplate } =
    useTemplates();
  const navigate = useNavigate();
  const form = useRef<FormikProps<IMessageTemplate>>(null);

  const back = useCallback(
    () => navigate(templateToEdit?.id ? "../../" : "../"),
    [templateToEdit, navigate]
  );

  const submit = useCallback(() => {
    if (form.current?.isValid) {
      if (templateToEdit.id)
        editTemplate({ ...form.current.values, id: templateToEdit.id });
      else addTemplate(form.current.values);
      navigate(templateToEdit?.id ? "../../../egne-maler" : "../../egne-maler");
    }
  }, [form, addTemplate, editTemplate, templateToEdit, navigate]);

  return (
    <>
      <VStack spacing="s">
        <UIText as="h1" size="28" className={styles.title}>
          Opprett egen tekstmal
        </UIText>
        {!isLoading && (
          <div className={styles.backButtonContainer}>
            <Link
              icon={ArrowLeft}
              onClick={back}
              reverse
              className={styles.backButton}
            >
              Alle tekstmaler
            </Link>
          </div>
        )}
      </VStack>

      {isLoading ? (
        <Loading />
      ) : (
        <Form
          innerRef={form}
          onSubmit={submit}
          initialValues={templateToEdit}
          validationSchema={object({
            title: string().label("Tittel på mal").required(),
            content: string().label("Tekst").required(),
          })}
          className={styles.form}
          validateOnChange
        >
          <VStack spacing="s" responsive>
            <VStack>
              <Label htmlFor="title">Tittel på mal</Label>
              <FormikInputText id="title" name="title" required />
            </VStack>
            <VStack>
              <Label htmlFor="content">Tekst</Label>
              <FormikInputText
                type="textarea"
                rows={7}
                id="content"
                name="content"
                required
              />
            </VStack>
          </VStack>
        </Form>
      )}

      <div className={styles.buttons}>
        <Button theme="outline" onClick={back}>
          Avbryt
        </Button>
        {!isLoading && (
          <Button type="submit" theme="action" onClick={submit}>
            Lagre mal
          </Button>
        )}
      </div>
    </>
  );
};
