import React from "react";
import {
  DialogOverlay,
  DialogOverlayProps,
  DialogContent,
} from "@reach/dialog";
import styles from "./styles.module.css";
import { defaultCategories, useTemplates } from "./hooks/useTemplates";
import { TemplatesView } from "./TemplatesView";
import { TemplateEditView } from "./TemplatesEditView";

interface ITemplateModalProps extends DialogOverlayProps {
  isOpen: boolean;
  title: string;
  onDismiss: () => void;
  editing?: boolean;
  onUse: (content: string) => void;
}

export const TemplateModal: React.FC<ITemplateModalProps> = ({
  title,
  editing,
  onUse,
  ...props
}) => {
  const { categories, isLoading } = useTemplates();
  const allCategories = [...categories, ...defaultCategories];

  return (
    <DialogOverlay className={styles.backdrop} {...props}>
      <DialogContent aria-label={title} className={styles.body}>
        {editing ? (
          <TemplateEditView />
        ) : (
          <TemplatesView
            isLoading={isLoading}
            categories={allCategories}
            onDismiss={props.onDismiss}
            onUse={onUse}
          />
        )}
      </DialogContent>
    </DialogOverlay>
  );
};
