import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

import {
  Loading,
  MenuItem,
  MenuItemActiveClassName,
  EmptyState,
  UIText,
  Dropdown,
  Card,
  CardSection,
  InputCheckbox,
  VStack,
  InputText,
  Badge,
} from "therese";

import { address } from "../../utils/address";
import { SplitView, ISplitViewProps, View } from "../SplitView";
import { Container, IContainerProps } from "../Layout/Layout";
import { useProperties } from "../useAgentProperties";
import { usePortalQuery } from "../usePortalQuery";
import { Pagination } from "../AgentPagination";
import { usePreferences, usePreferencesMutation } from "../usePreferences";
import { AgentPhaseFilter } from "../AgentPhaseFilter/AgentPhaseFilter";
import { NavLink } from "../NavLink";

import { agentPhaseFilter } from "../../utils/phaseText";

import { ReactComponent as ArrowIcon } from "symbols/arrow_up_right.svg";
import styles from "./styles.module.css";
import { ReactComponent as SearchIcon } from "symbols/search.svg";
import useDebouncedValue from "../useDebouncedValue";

interface IAgentLayoutProps extends Pick<ISplitViewProps, "mapKeys"> {
  title: string;
  element: ReactElement;
  path?: string;
  children?: ReactNode;
  showMessageCount?: boolean;
}

function AgentLayout(props: IAgentLayoutProps) {
  const { mutate } = usePreferencesMutation();
  const { data: preferences } = usePreferences();
  const [propertiesFilter, setPropertiesFilter] = useState<string>("");
  const { value: propertiesFilterDebounced, debouncing } = useDebouncedValue(
    propertiesFilter,
    1000
  );
  const { data, isLoading, isFetching } = useProperties();

  useEffect(() => {
    mutate({ offset: 0, filter: propertiesFilterDebounced });
  }, [propertiesFilterDebounced, mutate]);

  useEffect(() => {
    const filterValue = preferences?.filter || "";
    setPropertiesFilter(filterValue);
  }, []);

  const loading = isLoading || debouncing || isFetching;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SplitView
            title={props.title}
            mapKeys={props.mapKeys}
            headerAside={<AgentPhaseFilter />}
            filterInput={
              <InputText
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPropertiesFilter(e.target.value)
                }
                value={propertiesFilter}
                name="properties-filter"
                id="properties-filter"
                placeholder="Søk etter bolig"
                icon={<SearchIcon />}
              />
            }
          >
            {loading && (
              <>
                <br />
                <Loading skipDelay />
              </>
            )}
            {!loading &&
              data?.properties.map((p) => {
                return (
                  <MenuItem
                    key={p.id}
                    as={NavLink}
                    to={String(p.id)}
                    activeClassName={MenuItemActiveClassName}
                    badge={
                      props.showMessageCount &&
                      !!p.status.unread_messages && (
                        <Badge theme="circle" aria-hidden>
                          {p.status.unread_messages}
                        </Badge>
                      )
                    }
                  >
                    {address.toDisplay(p.address)}
                  </MenuItem>
                );
              })}

            {!loading && data !== undefined ? (
              <Pagination
                pagination={data.pagination}
                onChange={(offset) => {
                  mutate({ offset });
                }}
              />
            ) : null}
          </SplitView>
        }
      >
        {props.children || (
          <>
            <Route path={props.path || ":sale_id"} element={props.element} />
            <Route
              path=""
              element={
                <Container>
                  <EmptyState>Velg oppdrag fra menyen</EmptyState>
                </Container>
              }
            />
          </>
        )}
      </Route>
    </Routes>
  );
}

interface IAgentViewProps extends Pick<IContainerProps, "width"> {
  children: ReactNode;
}

function AgentView(props: IAgentViewProps) {
  const { sale_id } = useParams();
  const navigate = useNavigate();
  const { data: property, isLoading } = usePortalQuery<IAdminProperty>(
    "agents/property",
    { onError: () => navigate("../") } // TODO: Show error if sale doesn't exist ? (favro DNB-4699)
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <View
      header={
        property && sale_id ? (
          <div className={styles.header}>
            <UIText as="h3" bold>
              {address.toDisplay(property.address)}
            </UIText>
            <div>
              <Dropdown title="Vis portal">
                <Card>
                  <a
                    href={getPortalUrl(sale_id) + "?mode=sell"}
                    className={styles.link}
                  >
                    Som selger <ArrowIcon />
                  </a>
                  <a
                    href={getPortalUrl(sale_id) + "?mode=buy"}
                    className={styles.link}
                  >
                    Som kjøper <ArrowIcon />
                  </a>
                  {property.spouse ? (
                    <a
                      href={getPortalUrl(sale_id) + "?mode=spouse"}
                      className={styles.link}
                    >
                      Som ektefelle <ArrowIcon />
                    </a>
                  ) : null}
                </Card>
              </Dropdown>
            </div>
          </div>
        ) : null
      }
    >
      {property && (
        <div className={styles.root}>
          <Container padding="s" width={props.width}>
            {props.children}
          </Container>
        </div>
      )}
    </View>
  );
}

function getPortalUrl(saleId: string): string {
  const origin = window.location.origin;
  const baseUrl = origin.replace("megler.", "").replace("1", "0");
  return `${baseUrl}/${saleId}`;
}

function Filter() {
  const { mutate } = usePreferencesMutation();
  const { data } = usePreferences();

  return (
    <Dropdown title="Filter">
      <Card as="form">
        <CardSection>
          <VStack spacing="xxs">
            {agentPhaseFilter.items.map((item) => {
              const checked = data?.phase.includes(item.value);
              return (
                <InputCheckbox
                  key={`filter:${agentPhaseFilter}::${item.value}`}
                  {...item}
                  name={agentPhaseFilter.name}
                  checked={checked}
                  onChange={(event) => {
                    event.stopPropagation();
                    const newValues = [...(data?.phase as string[])];

                    if (!checked) {
                      newValues.push(event.target.value);
                    } else {
                      const idx = newValues.indexOf(item.value);
                      newValues.splice(idx, 1);
                    }

                    mutate({ [event.target.name]: newValues });
                  }}
                />
              );
            })}
          </VStack>
        </CardSection>
      </Card>
    </Dropdown>
  );
}
export { AgentView, AgentLayout };
