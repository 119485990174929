import { Link } from "react-router-dom";

import { Logo as LogoMark } from "@logo";

import { ILogoProps } from "./types";
import styles from "./styles.module.css";

interface IBaseLogoProps extends ILogoProps {
  to?: string;
  className?: string;
  centered?: boolean;
}

function Logo({ to, className, centered, ...props }: IBaseLogoProps) {
  if (to === undefined) {
    return (
      <h1 className={className}>
        <span className="visuallyhidden">{SITE_NAME}</span>
        <LogoMark centered={centered} {...props} />
      </h1>
    );
  }

  return (
    <h1 className={className}>
      <Link to={to} className={styles.logo} aria-label="hjem">
        <span className="visuallyhidden">{SITE_NAME}</span>
        <LogoMark centered={centered} {...props} />
      </Link>
    </h1>
  );
}

export { Logo };
