import { Options } from "redaxios";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { usePortalParams } from "./usePortalParams";

export type IUsePortalQueryResult<TData, TError> = UseQueryResult<
  TData,
  TError
> & {
  queryKey: QueryKey;
};

function usePortalQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  qk: string | [string, Record<string, Record<string, unknown>>],
  options?: UseQueryOptions<TQueryFnData, TError, TData>
): IUsePortalQueryResult<TData, TError> {
  const baseParams = usePortalParams();
  const queryKey = mergeQueryKeyParams(qk, baseParams);

  return {
    ...useQuery<TQueryFnData, TError, TData>(queryKey, options),
    queryKey,
  };
}

function mergeQueryKeyParams(
  qk: string | [string, Options],
  baseParams: IPortalParams
): QueryKey {
  let queryKey: QueryKey;
  if (typeof qk === "string") {
    queryKey = [qk, { params: baseParams }];
  } else {
    const [key, attributes = {}] = qk;
    const { params = {}, ...attrs } = attributes;
    queryKey = [key, { params: { ...baseParams, ...params }, ...attrs }];
  }
  return queryKey;
}

export { usePortalQuery };
